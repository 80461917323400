// @flow

import React, { useMemo } from 'react';
import type { Node } from 'react';
import { RouteConfigContext } from 'shared_data/providers/routeconfig/Context';

type Props = {
  children: any,
  value: Object,
};

/**
 * RouteConfigProvider
 *
 * @param {Props} props
 * @returns {Node}
 */
export const RouteConfigProvider = ({ children, value = {} }: Props): Node => {
  const contextValue = useMemo(() => value, [value]);

  return <RouteConfigContext.Provider value={contextValue}>{children}</RouteConfigContext.Provider>;
};
