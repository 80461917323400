// @flow

import React, { useEffect, useMemo } from 'react';
import type { Node } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applicationStoreUpdateLocales } from 'shared_services/redux/actions/application/store';
import { application as CONFIG_APP } from 'Config';
import {
  selectVisitorLocale,
  selectMeVisitorLocale,
} from 'shared_services/redux/selectors/visitor';
import {
  getLocale,
  getLocaleConfig,
  extractLocaleFromUrl,
} from 'shared_services/riseart/utils/RouteUtils';
import { LocaleContext } from 'shared_data/providers/locale/Context';

type Props = {
  isSSR: boolean,
  urlLanguage: ?string,
  location: Object,
  children: Function,
};

/**
 * AppLocale
 *
 * Determines the locale based on url and auth locales (or falls back to default)
 * and dispatches an event to store the locale in redux store
 *
 * @param {Props} props
 * @returns {Node}
 */
export const AppLocale = ({ isSSR, urlLanguage, location, children }: Props): Node => {
  const dispatch: Function = useDispatch();
  const visitorLocale = useSelector(selectVisitorLocale);
  const meVisitorLocale = useSelector(selectMeVisitorLocale);
  const pathname = location && location.pathname;
  const routeLocale = useMemo(
    () =>
      getLocaleConfig(urlLanguage, 'basePath') ||
      extractLocaleFromUrl(pathname) ||
      getLocaleConfig(true, 'isDefault'),
    [pathname, urlLanguage],
  );
  const calcVisitorLocale = isSSR ? visitorLocale : meVisitorLocale || visitorLocale;
  const userLocale = useMemo(
    () =>
      getLocale(
        urlLanguage,
        // use meVisitorLocale (if set) client side,
        // so that this component will check with latest selected locale from user
        // after readMe is fetched, and not before that
        // This is a fix for the issue reported here https://riseart.atlassian.net/browse/RIS-6473
        calcVisitorLocale,
        CONFIG_APP.i18n.locales,
      ),
    [calcVisitorLocale, urlLanguage],
  );

  // useEffect is only executed on client
  useEffect(() => {
    dispatch(applicationStoreUpdateLocales({ userLocale, routeLocale }));
  }, [dispatch, userLocale, routeLocale]);

  // called on server to workaround the useEffect not called on server issue
  if (isSSR) {
    dispatch(applicationStoreUpdateLocales({ userLocale, routeLocale }));
  }

  const combinedContextValue = useMemo(
    () => ({ userLocale, routeLocale }),
    [userLocale, routeLocale],
  );
  return <LocaleContext.Provider value={combinedContextValue}>{children}</LocaleContext.Provider>;
};
