// @flow

import React, { useContext } from 'react';
import type { Node } from 'react';
import { ErrorBoundary } from 'shared_data/providers/errors/ErrorBoundary';
import { ErrorPageRenderer } from 'shared_data/providers/errors/ErrorPageRenderer';
import { ErrorPage } from 'shared_components/pages/error/Error';
import { MetaConfigProvider } from 'shared_data/providers/meta/Config';
import { AppLocale } from 'shared_components/routers/Locale';
import { SSRContext } from 'shared_data/providers/ssr/Context';
import { I18nProviderConnected } from 'shared_components/i18n/I18nProviderConnected';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { AclGateConnected } from 'shared_data/providers/acl/GateConnected';
import { RouteConfigProvider } from 'shared_data/providers/routeconfig/Provider';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { InitialAuthActionsEmitter } from 'shared_data/providers/token/InitialAuthActionsEmitter';
import { IsomorphicRipple } from 'shared_components/common/preloader/IsomorphicRipple';
import { RedirectWithStatus } from 'shared_components/routers/RedirectWithStatus';
import { MetaInit } from 'shared_hocs/meta/MetaInit';
import { getMetaByModule } from 'shared_services/riseart/meta/utils';
import { UIInit } from 'shared_hocs/gui/UIInit';
import { MetaRedirect } from 'shared_components/routers/MetaRedirect';
import { UrlTranslationsProvider } from 'shared_data/providers/url/TranslationsProvider';
import { GoogleAuthCallbackProvider } from 'shared_components/auth/google/GoogleProvider';
import { I18nComponentsLoader } from 'shared_components/i18n/ComponentsLoader';

type Props = {
  children: Function,
  pageRouteConfig: Object,
  location: Object,
  match: Object,
};

/**
 * AppPage
 *
 * @param {Props} props
 * @returns {Node}
 */
export const AppPage = ({ pageRouteConfig, location, match, children }: Props): Node => {
  const { isSSR } = useContext(SSRContext);

  return (
    <AppLocale isSSR={isSSR} urlLanguage={match.params.lang} location={location}>
      <I18nProviderConnected>
        <ErrorBoundary errorRender={(error) => <ErrorPage error={error} />}>
          <GoogleAuthCallbackProvider pageConfig={pageRouteConfig} />
          <MetaConfigProvider isSSR={isSSR} pageRouteConfig={pageRouteConfig} />
          <UrlTranslationsProvider>
            <ErrorPageRenderer>
              <RouteConfigProvider value={pageRouteConfig}>
                <I18nComponentsLoader dataKey="pageMeta">
                  {(pageMeta) => (
                    <MetaInit
                      isSSR={isSSR}
                      configMeta={pageRouteConfig}
                      pageMeta={getMetaByModule(pageMeta, pageRouteConfig.module)}
                    />
                  )}
                </I18nComponentsLoader>
                {!isSSR ? <UIInit routeConfig={pageRouteConfig} /> : null}
                <MetaRedirect />
                <InitialAuthActionsEmitter />
                <AclGateConnected
                  allowedRoles={pageRouteConfig && pageRouteConfig.acl && pageRouteConfig.acl.roles}
                  loader={
                    <IsomorphicRipple
                      isActive
                      isFullScreen
                      customStyles={{ backgroundColor: '#ffffff' }}
                    />
                  }
                  denied={() => (
                    <RedirectWithStatus
                      to={UrlAssembler.byRouteKey(
                        (pageRouteConfig &&
                          pageRouteConfig.acl &&
                          ((pageRouteConfig.acl.authPage && pageRouteConfig.acl.authPage.key) ||
                            pageRouteConfig.acl.forward)) ||
                          'login',
                        {
                          ...((pageRouteConfig &&
                            pageRouteConfig.acl &&
                            pageRouteConfig.acl.authPage &&
                            pageRouteConfig.acl.authPage.options) ||
                            {}),
                          ...(pageRouteConfig && pageRouteConfig.acl && pageRouteConfig.acl.forward
                            ? {}
                            : {
                                search: {
                                  forward: LocationManager.get('uri'),
                                },
                              }),
                        },
                      )}
                    />
                  )}
                >
                  {/* Load page */}
                  {children}
                </AclGateConnected>
              </RouteConfigProvider>
            </ErrorPageRenderer>
          </UrlTranslationsProvider>
        </ErrorBoundary>
      </I18nProviderConnected>
    </AppLocale>
  );
};
