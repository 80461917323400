// @flow

import { useEffect } from 'react';
import type { Node } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cookies as CONFIG_COOKIES } from 'Config';
import { ME_ACTIONS } from 'shared_services/redux/actions/container';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';
import { getInitialAuthActions, getCookieValue } from 'shared_services/redux/helpers/auth';
import { getQueryStringParam } from 'shared_services/riseart/utils/RouteUtils';
import { selectAuthStatus, selectAuthToken } from 'shared_services/redux/selectors/auth';
import {
  selectMeLoading,
  selectMeLoaded,
  selectMeHasError,
} from 'shared_services/redux/selectors/me';
import { selectVisitorProperty } from 'shared_services/redux/selectors/visitor';

type Props = { children?: Node };

/**
 * InitialAuthActionsEmitter
 *
 * @param {Props} props
 * @returns {Node}
 */
export function InitialAuthActionsEmitter({ children }: Props): Node {
  const dispatch: Function = useDispatch();
  const meVisitorId = useSelector(selectVisitorProperty('id'));
  const meLoading = useSelector(selectMeLoading);
  const meLoaded = useSelector(selectMeLoaded);
  const meHasError = useSelector(selectMeHasError);
  const token = useSelector(selectAuthToken);
  const authLoaded = useSelector(selectAuthStatus('loaded'));
  const shouldFetchMe =
    authLoaded && (!meVisitorId || (meVisitorId && !meLoaded && !meHasError && !meLoading));

  // Called on client side only (when component mounted)
  useEffect(() => {
    const determineInitialActions = async () =>
      getInitialAuthActions(
        getCookieValue(CONFIG_COOKIES.token.name),
        getCookieValue(CONFIG_COOKIES.visitor.name),
        getQueryStringParam(LocalizedConfig.get('navigation.uri.query.params.visitor')),
        token,
      );
    // Resolve auth actions to trigger and dispatch
    determineInitialActions().then((initialAuthActions) => {
      if (initialAuthActions) {
        [...initialAuthActions, ...(shouldFetchMe ? [{ type: ME_ACTIONS.ME_FETCH }] : [])].forEach(
          (action) => dispatch(action),
        );
      }
    });
  }, []); //eslint-disable-line

  return children;
}
