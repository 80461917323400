// @flow

import React from 'react';
import type { Node } from 'react';
import { useSelector } from 'react-redux';
import { AclGate } from 'shared_data/providers/acl/Gate';
import type { AclGateProps } from 'shared_data/providers/acl/Gate';
import { selectAclRole, selectAuthToken } from 'shared_services/redux/selectors/auth';

type AclGateConnectedProps = {
  ...AclGateProps,
  role?: string | null,
  loader?: Node,
};

/**
 * AclGateConnected
 *
 * @param {Props} props
 * @returns {Node}
 */
export function AclGateConnected({ children, loader, ...restProps }: AclGateConnectedProps): Node {
  const visitorRole = useSelector(selectAclRole);
  const token = useSelector(selectAuthToken);

  return token ? (
    <AclGate role={visitorRole} {...restProps}>
      {children}
    </AclGate>
  ) : (
    loader
  );
}
