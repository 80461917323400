// @flow

import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ExpiredToken, InvalidToken } from '@riseart/jwt';
import { libs as CONFIG_LIBS } from 'Config';
import { auth as AUTH_ENUM } from 'Enum';
import { ErrorService } from 'shared_services/riseart/errors/ErrorService';
import { localeSelector } from 'shared_services/redux/selectors/locale';
import { authTokenFetch } from 'shared_services/redux/actions/auth/auth';
import { errorAdd } from 'shared_services/redux/actions/errors/errors';
import { getAuthForwardDestination } from 'shared_services/riseart/utils/AuthUtils';
import { selectMeLoading } from 'shared_services/redux/selectors/me';
import { selectUserId } from 'shared_services/redux/selectors/user';
import { selectVisitorProperty } from 'shared_services/redux/selectors/visitor';
import { selectAuthToken, selectAuthStatus } from 'shared_services/redux/selectors/auth';

/**
 * useGoogleAuthListener
 *
 * @param {Props} props
 * @returns {Object}
 */
export const useGoogleAuthListener = (options: Object): Object => {
  const { provider = 'google', authParams = null } = options || {};
  const dispatch = useDispatch();
  const token = useSelector(selectAuthToken);
  const authLoading = useSelector(selectAuthStatus('loading'));
  const meLoading = useSelector(selectMeLoading) || {};
  const userId = useSelector(selectUserId);
  const visitorId = useSelector(selectVisitorProperty('id'));
  const locale = useSelector(localeSelector, shallowEqual);
  const { ui: uiConfig } = (options && options.pageConfig) || {};
  const { disallowGoogleAuthPrompt } = uiConfig || {};
  const [loading, setLoading] = useState(false);
  const [libLoaded, setLibLoaded] = useState(0);
  const [showPrompt, setShowPrompt] = useState(false);
  const actionTokenFetch = useCallback(
    (data: Object) => dispatch(authTokenFetch(data)),
    [dispatch],
  );
  const actionErrorAdd = useCallback((data: Object) => dispatch(errorAdd(data)), [dispatch]);
  const storeData = { token, visitorId, locale };
  const isLoggedIn = visitorId ? !!userId : undefined;
  const isAuthLoading = authLoading || meLoading;

  /**
   * handleLogin
   *
   * @param token
   */
  const handleLogin = useCallback(
    (authData) => {
      setLoading(true);

      const { credential: token } = authData || {};

      try {
        if (token) {
          actionTokenFetch({
            trigger: {
              data: {
                location: authParams
                  ? getAuthForwardDestination(authParams, storeData.locale)
                  : null,
              },
            },
            authModule: AUTH_ENUM.modules.WEBSITE_OAUTH,
            currentToken: storeData.token,
            authProviderToken: token,
            authProvider: provider,
            marketingCampaignId: authParams && authParams.marketingCampaignId,
            referrerId: authParams && authParams.referrerId,
            visitorId: storeData.visitorId,
            ...(authParams && authParams.quizResponseId
              ? { quizResponseId: authParams.quizResponseId }
              : null),
          });
        } else {
          actionErrorAdd(ErrorService.mapNotification({ detail: 'forms.auth_form.login_error' }));
          setLoading(false);
        }
      } catch (Error) {
        if (Error instanceof ExpiredToken || Error instanceof InvalidToken) {
          actionTokenFetch({
            visitorId: storeData.visitorId,
            authModule: AUTH_ENUM.modules.WEBSITE_OAUTH,
          });
        } else {
          actionErrorAdd(ErrorService.mapNotification({ detail: 'forms.auth_form.login_error' }));
          setLoading(false);
        }
        setLoading(false);
      }
    },
    [
      authParams,
      actionTokenFetch,
      actionErrorAdd,
      provider,
      storeData.locale,
      storeData.visitorId,
      storeData.token,
    ],
  );

  // Showing Google prompt
  useEffect(() => {
    if (showPrompt && !isAuthLoading && !isLoggedIn) {
      window.google.accounts.id.prompt();
    }
  }, [showPrompt, isLoggedIn, isAuthLoading]);

  // Check if state should change to trigger the Google prompt after the library was loaded
  useEffect(() => {
    const isGoogleAuthInit =
      typeof window !== 'undefined' &&
      window.google &&
      window.google.accounts &&
      window.google.accounts.id &&
      window.googleAccountsIdInitialized;
    const showPrompt =
      CONFIG_LIBS.google.allowPrompt && (!uiConfig || (uiConfig && !disallowGoogleAuthPrompt));

    if (!showPrompt || isAuthLoading) {
      return;
    }

    if (isGoogleAuthInit && libLoaded <= 0) {
      setLibLoaded(1);

      if (showPrompt && isLoggedIn !== undefined && !isLoggedIn) {
        // Call with a very small delay, in cases when authLoading state has to be triggered to load new data
        setTimeout(() => {
          setShowPrompt(true);
        }, 10);
      }

      window[CONFIG_LIBS.google.callbackName] = handleLogin;
    } else if (libLoaded <= 0) {
      setTimeout(() => {
        setLibLoaded(libLoaded - 1);
      }, 200);
    }
  }, [handleLogin, uiConfig, disallowGoogleAuthPrompt, isAuthLoading, isLoggedIn, libLoaded]);

  return {
    loading,
  };
};
