// @flow

import React, { useContext, useMemo } from 'react';
import type { Node } from 'react';
import querystring from 'query-string';
import { useLocation } from 'react-router-dom';
import { stringifyHash } from '@riseart/fe-utils';
import { LocaleContext } from 'shared_data/providers/locale/Context';
import { UrlTranslationsContext } from 'shared_data/providers/url/TanslationsContext';
import { URL_PARTS, UrlTranslator } from 'shared_services/riseart/url/Translator';

type Props = {
  children: Function,
};

/**
 * UrlTranslationsProvider
 *
 * @param {Props} props
 * @returns
 */
export function UrlTranslationsProvider({ children }: Props): Node {
  const { routeLocale } = useContext(LocaleContext) || {};
  UrlTranslator.config(routeLocale && routeLocale.name);
  const location = useLocation();
  const { hash, pathname, search } = location;
  const translatedQueryString = querystring.stringify(
    UrlTranslator.translateFrom(search, URL_PARTS.QUERY),
  );
  const translatedSearch = translatedQueryString ? `?${translatedQueryString}` : '';
  const translatedHash = stringifyHash(UrlTranslator.translateFrom(hash, URL_PARTS.HASH));
  const contextValue = useMemo(
    () => ({
      url: pathname,
      query: translatedSearch,
      hash: translatedHash,
      translator: UrlTranslator,
      translatedLocation: { ...location, search: translatedSearch, hash: translatedHash },
    }),
    [pathname, translatedSearch, translatedHash, location],
  );

  return (
    <UrlTranslationsContext.Provider value={contextValue}>
      {children}
    </UrlTranslationsContext.Provider>
  );
}
