// @flow

import type { Node } from 'react';
import { isRoleAllowedForACL } from '@riseart/fe-utils';
import { application as CONFIG_APP } from 'Config';

export type AclGateProps = {
  role: string | null | void,
  allowedRoles: Array<string>,
  children: Node,
  denied: () => Node,
};

/**
 * AclGate
 *
 * @param {AclGateProps} props
 * @returns {Node}
 */
export function AclGate({ role, allowedRoles, children, denied }: AclGateProps): Node {
  const isAllowed = role && isRoleAllowedForACL(allowedRoles, role, CONFIG_APP.acl.rolesHierarchy);

  return isAllowed ? children : denied();
}
