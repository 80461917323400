// @flow

import React from 'react';
import type { Node } from 'react';
import { Switch, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import { timeout } from 'promise-timeout';
import { router as CONFIG_ROUTER } from 'Config';
import { UrlChangeTracker } from 'shared_components/common/urlchangetracker/UrlChangeTracker';
import { IsomorphicRipple } from 'shared_components/common/preloader/IsomorphicRipple';
import { AppPage } from 'shared_components/app/Page';

// Loadable timeout (ms) and fallback component
const LOADABLE_TIMEOUT = 10000;
const FallbackComponent = (
  <IsomorphicRipple isFullScreen isActive customStyles={{ backgroundColor: '#ffffff' }} />
);

// List of view components
const VIEWS_COMPONENTS = {
  Home: loadable(() => timeout(import('shared_components/pages/home/Home'), LOADABLE_TIMEOUT), {
    fallback: FallbackComponent,
  }),
  ArtList: loadable(() => timeout(import('shared_components/pages/art/List'), LOADABLE_TIMEOUT), {
    fallback: FallbackComponent,
  }),
  ArtDetails: loadable(
    () => timeout(import('shared_components/pages/art/Details'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  ArtistList: loadable(
    () => timeout(import('shared_components/pages/artist/List'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  ArtistProfile: loadable(
    () => timeout(import('shared_components/pages/artist/Profile'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  ArtistCv: loadable(() => timeout(import('shared_components/pages/artist/Cv'), LOADABLE_TIMEOUT), {
    fallback: FallbackComponent,
  }),
  ArtistStudio: loadable(
    () => timeout(import('shared_components/pages/artist/Studio'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  ArtistNews: loadable(
    () => timeout(import('shared_components/pages/artist/News'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  ActivityList: loadable(
    () => timeout(import('shared_components/pages/activity/List'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  BlogHome: loadable(() => timeout(import('shared_components/pages/blog/Home'), LOADABLE_TIMEOUT), {
    fallback: FallbackComponent,
  }),
  BlogCategory: loadable(
    () => timeout(import('shared_components/pages/blog/Category'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  BlogArticle: loadable(
    () => timeout(import('shared_components/pages/blog/Article'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  GuidesHome: loadable(
    () => timeout(import('shared_components/pages/guides/Home'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  GuidesCategory: loadable(
    () => timeout(import('shared_components/pages/guides/Category'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  GuidesArticle: loadable(
    () => timeout(import('shared_components/pages/guides/Article'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  EventList: loadable(
    () => timeout(import('shared_components/pages/event/List'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  EventDetails: loadable(
    () => timeout(import('shared_components/pages/event/Details'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  CollectionList: loadable(
    () => timeout(import('shared_components/pages/collection/List'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  CollectionDetails: loadable(
    () => timeout(import('shared_components/pages/collection/Details'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  QuizStart: loadable(
    () => timeout(import('shared_components/pages/quiz/Start'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  QuizQuestions: loadable(
    () => timeout(import('shared_components/pages/quiz/Questions'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  QuizRegister: loadable(
    () => timeout(import('shared_components/pages/quiz/Register'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  QuizLogin: loadable(
    () => timeout(import('shared_components/pages/quiz/Login'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  QuizResult: loadable(
    () => timeout(import('shared_components/pages/quiz/Result'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  QuizProfile: loadable(
    () => timeout(import('shared_components/pages/quiz/Profile'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  Cart: loadable(() => timeout(import('shared_components/pages/cart/Cart'), LOADABLE_TIMEOUT), {
    fallback: FallbackComponent,
  }),
  Checkout: loadable(
    () => timeout(import('shared_components/pages/cart/Checkout'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  CheckoutAuth: loadable(
    () => timeout(import('shared_components/pages/cart/CheckoutAuth'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  OrderConfirmation: loadable(
    () => timeout(import('shared_components/pages/order/Confirm'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  Login: loadable(() => timeout(import('shared_components/pages/auth/Login'), LOADABLE_TIMEOUT), {
    fallback: FallbackComponent,
  }),
  Register: loadable(
    () => timeout(import('shared_components/pages/auth/Register'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  ForgotPassword: loadable(
    () => timeout(import('shared_components/pages/auth/ForgotPassword'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  ResetPassword: loadable(
    () => timeout(import('shared_components/pages/auth/ResetPassword'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  Signout: loadable(
    () => timeout(import('shared_components/pages/auth/Signout'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  MeWishlist: loadable(
    () => timeout(import('shared_components/pages/me/Wishlist'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  MeArtists: loadable(
    () => timeout(import('shared_components/pages/me/Artists'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  MeProfile: loadable(
    () => timeout(import('shared_components/pages/me/Profile'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.MeProfile,
      fallback: FallbackComponent,
    },
  ),
  MeAccount: loadable(
    () => timeout(import('shared_components/pages/me/Account'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.MeAccount,
      fallback: FallbackComponent,
    },
  ),
  MePrivacy: loadable(
    () => timeout(import('shared_components/pages/me/Privacy'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.MePrivacy,
      fallback: FallbackComponent,
    },
  ),
  MeOffers: loadable(() => timeout(import('shared_components/pages/me/Offers'), LOADABLE_TIMEOUT), {
    resolveComponent: (components) => components.MeOffers,
    fallback: FallbackComponent,
  }),
  MeOfferDetails: loadable(
    () => timeout(import('shared_components/pages/me/Offer'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.MeOfferDetails,
      fallback: FallbackComponent,
    },
  ),
  CmsRouter: loadable(
    () => timeout(import('shared_components/pages/common/CmsRouter'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
  NotFound: loadable(
    () => timeout(import('shared_components/pages/error/NotFound'), LOADABLE_TIMEOUT),
    {
      fallback: FallbackComponent,
    },
  ),
};

/**
 * AppRouter
 *
 * @returns {Router}
 * @constructor
 */
export const AppRouter = (): Node => (
  <UrlChangeTracker>
    <Switch>
      {CONFIG_ROUTER.map((route) => (
        <Route
          exact={route.exact}
          path={route.path}
          key={route.key}
          render={(props: Object) => {
            const ViewComponent = VIEWS_COMPONENTS[route.component];

            if (!ViewComponent) {
              throw new Error(`View Component ${route.component} is not implemented yet.`);
            }

            // fallback component from route configuration
            const fallbackRouteComponent = route.fallbackComponent
              ? VIEWS_COMPONENTS[route.fallbackComponent]
              : null;

            return (
              <AppPage location={props.location} match={props.match} pageRouteConfig={route}>
                <ViewComponent
                  staticContext={props.staticContext}
                  routeConfig={route}
                  {...(fallbackRouteComponent ? { fallbackComponent: fallbackRouteComponent } : {})}
                />
              </AppPage>
            );
          }}
        />
      ))}
    </Switch>
  </UrlChangeTracker>
);
