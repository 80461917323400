// @flow

import { useCallback, useRef } from 'react';
import type { Node } from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MetaService } from 'shared_services/riseart/meta/Meta';
import { guiUpdate } from 'shared_services/redux/actions/application/gui';
import { pageMetaResolved } from 'shared_services/redux/actions/application/meta';
import {
  applicationStoreUpdateRedirects,
  applicationStoreReset,
} from 'shared_services/redux/actions/application/store';

type Props = {
  isSSR: boolean,
  pageRouteConfig: Object,
  children?: ?any,
};

/**
 * MetaConfigProvider
 *
 * @param {Props} props
 * @returns {Node}
 */
export function MetaConfigProvider({ isSSR, pageRouteConfig, children = null }: Props): Node {
  const isInitialCall = useRef(true);
  const intl = useIntl();
  const match = useRouteMatch();
  const dispatch: Function = useDispatch();

  // Actions
  const actionGuiUpdate = useCallback(
    (key: string, value: any) => dispatch(guiUpdate(key, value)),
    [dispatch],
  );
  const actionPageMetaResolved = useCallback(
    (payload: Object) => dispatch(pageMetaResolved(payload)),
    [dispatch],
  );
  const actionReset = useCallback(() => dispatch(applicationStoreReset('redirects')), [dispatch]);
  const actionAppStoreUpdateRedirects = useCallback(
    (meta: Object) => dispatch(applicationStoreUpdateRedirects(meta)),
    [dispatch],
  );

  // Call this only once
  if (isInitialCall.current) {
    MetaService.config(
      { isSSR, match, intl, dispatch, pageRouteConfig },
      actionGuiUpdate,
      actionPageMetaResolved,
      actionAppStoreUpdateRedirects,
      actionReset,
    ).reset();

    isInitialCall.current = false;
  }

  return children;
}
