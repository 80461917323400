// @flow

import React, { useContext, useMemo } from 'react';
import type { Node } from 'react';
import { useParams } from 'react-router-dom';
import { RedirectWithStatus } from 'shared_components/routers/RedirectWithStatus';
import { RouteConfigContext } from 'shared_data/providers/routeconfig/Context';
import { useUrlParams } from 'shared_data/providers/url/useUrlParams';
import { useRedirects } from 'shared_services/redux/hooks/useRedirects';
import { LocaleContext } from 'shared_data/providers/locale/Context';
import { findMetaRedirect } from 'shared_services/riseart/meta/utils';

/**
 * MetaRedirect
 *
 * @param {Props} props
 * @returns {Node}
 */
export const MetaRedirect = (): Node => {
  const redirects = useRedirects();
  const params = useParams();
  const { routeLocale, userLocale } = useContext(LocaleContext);
  const { path } = useContext(RouteConfigContext);
  const { translatedLocation: location } = useUrlParams();
  const metaRedirect = useMemo(
    () =>
      findMetaRedirect(redirects, {
        currentParams: params,
        currentLocale: routeLocale,
        userLocale,
        location,
        routeConfigPath: path,
      }),
    [redirects, params, routeLocale, userLocale, location, path],
  );

  return metaRedirect && metaRedirect.url ? (
    <RedirectWithStatus to={metaRedirect.url} status={metaRedirect.status} />
  ) : null;
};
