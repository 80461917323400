// @flow

import React, { useContext } from 'react';
import type { Node } from 'react';
import { I18nProvider } from 'shared_components/i18n/I18nProvider';
import { LocaleContext } from 'shared_data/providers/locale/Context';

type Props = {
  children: any,
};

/**
 * I18nProviderConnected
 *
 * @param {Props} props
 * @returns {Node}
 */
export function I18nProviderConnected({ children }: Props): Node {
  const { routeLocale } = useContext(LocaleContext);

  return <I18nProvider locale={routeLocale}>{children}</I18nProvider>;
}
