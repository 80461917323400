// @flow

import React from 'react';
import type { Node } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { localeSelector } from 'shared_services/redux/selectors/locale';
import { LOCALE_COMPONENTS } from 'shared_locales/index';

type BaseProps = {
  dataKey: string,
  children: Function,
};

type Props = {
  ...BaseProps,
  locale?: string,
};

/**
 * getLocaleComponent
 *
 * @param {string} dataKey
 * @param {string} locale
 * @returns {Element}
 */
function getLocaleComponent(dataKey: string, locale: string): any {
  const LocaleComponent = LOCALE_COMPONENTS[dataKey] && LOCALE_COMPONENTS[dataKey][locale];

  if (!LocaleComponent) {
    throw new Error(
      `[I18nComponentsLoader] Component for '${dataKey}' with locale '${locale}' is not implemented yet.`,
    );
  }

  return LocaleComponent;
}

/**
 * I18nComponentsLoaderWithStoreLocale
 *
 * @param {Props} props
 * @returns {Node}
 */
function I18nComponentsLoaderWithStoreLocale({ dataKey, children }: BaseProps): Node {
  const { name: localeFromStore } = useSelector(localeSelector, shallowEqual) || {};
  const Component = getLocaleComponent(dataKey, localeFromStore);

  return <Component>{children}</Component>;
}

/**
 * I18nComponentsLoader
 *
 * @param {Props} props
 * @returns {Node}
 */
export const I18nComponentsLoader = ({ dataKey, locale, children }: Props): Node => {
  // Extract the compoent by passing locale
  if (locale) {
    const Component = getLocaleComponent(dataKey, locale);

    return <Component>{children}</Component>;
  }

  // Extract the component by using the store locale
  return (
    <I18nComponentsLoaderWithStoreLocale dataKey={dataKey}>
      {children}
    </I18nComponentsLoaderWithStoreLocale>
  );
};
